import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { colour_red_ribbon, colour_white } from "../assets/css/variables";

export const WarningBanner = () => {
  const { t } = useTranslation("messagesAndSettings");

  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
  };

  const isDemo = getCookie("OwnerType") === "demo";

  return (
    <WarningContainer>
      {isDemo && <Warning>{t("warning_banner")}</Warning>}
    </WarningContainer>
  );
};

const WarningContainer = styled.div`
  height: 3rem;
`;

const Warning = styled.div`
  align-content: center;
  background-color: ${colour_red_ribbon};
  bottom: 0;
  color: ${colour_white};
  display: flex;
  font-weight: bold;
  flex-direction: column;
  height: 3rem;
  justify-content: center;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;
