import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  colour_mine_shaft,
  colour_school_bus_yellow,
} from "../../assets/css/variables";

import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import getJSON from "../../utils/getJson";
import scenariosContent from "../../content/scenarios";
import session from "../../session";
import type { History, Message } from "../../utils/types";
import Layout, {
  LayoutHeader,
  LayoutContent,
  LayoutFooter,
} from "../layouts/Layout";
import HeaderSection from "../layouts/HeaderSection";
import { HelplineLink } from "../HelplineLink";
import { Loading } from "../subcomponents/Loading";
import { RightsLink } from "../RightsLink";
import { SessionContext } from "../contexts/SessionContext";
import { VerticalSpacing } from "../VerticalSpacing";
import { ApiOrg } from "./Services";
import { ApiHocProps, withApi } from "../../api";

type Props = {
  history: History;
  t: TFunction;
};

type State = {
  awaitingResponse: boolean;
  offeredScenarios: Array<string>;
  message: Message;
};

class Scenarios extends Component<ApiHocProps<Props>, State> {
  // grab context for a classy component
  static contextType = SessionContext;
  constructor(props: ApiHocProps<Props>) {
    super(props);

    this.state = {
      awaitingResponse: true,
      offeredScenarios: [],
      message: { text: "" },
    };

    this.scenarioCards = this.scenarioCards.bind(this);
  }

  getLocationState(): Message {
    const { history } = this.props;
    // @ts-expect-error - we need to fix the types
    if (typeof history.location.state?.message?.text === "string") {
      return history.location.state as Message;
    }
    return { text: "" };
  }

  handleError(e) {
    if (e.response)
      switch (e.response.status) {
        case 401:
        case 403:
          return session.deAuth(() =>
            this.props.history.push("/", {
              message: { text: getAxiosErrorMessage(e), type: "error" },
            }),
          );
        default:
      }
    return this.setState({
      awaitingResponse: false,
      message: {
        text: getAxiosErrorMessage(e),
        type: "error",
      },
    });
  }

  async componentDidMount() {
    const chosenService = localStorage.getItem("chosen-service");

    // set a default identity
    let product_identity = null;
    if (chosenService) {
      try {
        // Try to fetch organisation by id
        const orgData = await getJSON(
          this.props.Api,
          `/organisation/${chosenService}`,
        );
        if (orgData) {
          product_identity = orgData.product_identity;
        }
      } catch (err) {
        // Fallback to search all for backwards compatibility
        try {
          const organisations = await getJSON(this.props.Api, "/organisation");
          const orgData = organisations.find((org: ApiOrg) => {
            return org.id === parseInt(chosenService);
          });

          if (orgData) {
            product_identity = orgData.product_identity;
          }
        } catch (err) {
          return this.handleError(err);
        }
      }
    }
    // update the theme
    // TODO: fix the anys
    (this.context as any).updateTheme(product_identity);

    let offeredScenarios = [];
    try {
      const servicesUrl = chosenService
        ? `/user/services/${chosenService}/scenarios`
        : `/user/services/scenarios`;
      // Fetch the offered scenarios for a user's selected or specified services.)
      offeredScenarios = await getJSON(this.props.Api, servicesUrl);
    } catch (err) {
      return this.handleError(err);
    }

    this.setState({
      awaitingResponse: false,
      offeredScenarios: offeredScenarios,
    });
  }

  scenarioCards = () => {
    const { t } = this.props;
    const { awaitingResponse, offeredScenarios } = this.state;

    // If we're waiting on data, print nowt.
    if (awaitingResponse) {
      return <Loading message={t("pages:scenarios.loading_scenarios")} />;
    }

    // Compile the tag soup we'll be proffering for consumption.
    const scenarioCards = scenariosContent(t).map(function (scenario) {
      // Map the ones we want into an array
      return offeredScenarios.indexOf(scenario.id) >= 0 ? (
        <ScenarioCol xs={6} md={3} key={scenario.slug}>
          <div>
            <IconLink
              to={{
                pathname: "/scenarios/" + scenario.slug,
                state: { scenarioDef: scenario },
              }}
            >
              <ImageIcon src={scenario.image} alt="" />
              <span>{scenario.title}</span>
            </IconLink>
          </div>
        </ScenarioCol>
      ) : null;
    });

    // Check we have some
    return scenarioCards.filter(Boolean).length > 0 ? (
      scenarioCards
    ) : (
      // Print commiserations and apologies
      <p style={{ textAlign: "center" }}>
        {t("pages:scenarios.message_error_no_scenarios")}
      </p>
    );
  };

  render() {
    const { t } = this.props;
    const message = this.state.message
      ? this.state.message
      : this.getLocationState();
    const username =
      session.user && session.user.first_name
        ? session.user.first_name
        : t("defaults.user_firstname");
    const greeting = t("pages:scenarios.heading") + " " + username;

    // Only display the helpline element if we are returned a number from translation
    const showHelpline = t("pages:scenarios.link_helpline.number");

    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            heading={greeting}
            subheading={t("pages:scenarios.subheading")}
            message={message}
          />
        </LayoutHeader>
        <LayoutContent>
          <Row justify="center">{this.scenarioCards()}</Row>
          <VerticalSpacing />
        </LayoutContent>
        <LayoutFooter>
          <Row justify="center">
            <Col xs={10} md={6} lg={4}>
              <RightsLink />

              {showHelpline ? <HelplineLink /> : null}
            </Col>
          </Row>
        </LayoutFooter>
      </Layout>
    );
  }
}

const ScenarioCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const ImageIcon = styled.img`
  margin: auto;
  display: block;
  height: 5rem;
  width: 5rem;
`;

const IconLink = styled(Link)`
  background-size: 8em 8em;
  border-radius: 50%;
  color: ${colour_mine_shaft};
  display: inline-block;
  height: 8em;
  margin: 0.5em;
  padding: 1rem;
  width: 8em;

  span {
    text-align: center;
    display: block;
    margin-top: 0.2em;
  }

  &:hover,
  &:active {
    background-color: ${colour_school_bus_yellow};
    font-weight: bold;
  }
`;

export default withTranslation()(withApi(Scenarios));
