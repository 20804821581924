import React, { Component, Fragment } from "react";

import { Row, Col } from "react-grid-system";
import { withTranslation, Trans } from "react-i18next";
import type { TFunction } from "i18next";
import styled from "styled-components";

import getAxiosErrorMessage from "../../utils/getAxiosErrorMessage";
import session from "../../session";
import type { History, Message } from "../../utils/types";
import Layout, {
  LayoutHeader,
  LayoutContent,
  LayoutFooter,
} from "../layouts/Layout";
import HeaderSection from "../layouts/HeaderSection";
import { PurpleButtonAction } from "../subcomponents/ButtonAction";
import Checkbox from "../subcomponents/Checkbox";
import MessageBox from "../subcomponents/MessageBox";
import ProgressBars from "../subcomponents/ProgressBars";
import SubmitButton from "../subcomponents/SubmitButton";
import TextLink from "../subcomponents/TextLink";
import VisuallyHidden from "../subcomponents/VisuallyHidden";
import { getLocaleDefaults } from "../../utils/l10n";
import { ApiHocProps, withApi } from "../../api";
import { AxiosInstance } from "axios";
import { Loading } from "../subcomponents/Loading";

type Props = {
  history: History;
  t: TFunction;
};

type OrgRange = {
  category: string;
  distance: number;
};

export type ApiOrg = {
  /** @deprecated The api will no longer be sending this data */
  distance: null | number;
  country: string;
  id: number;
  name: string;
  lat: number;
  lng: number;
};

// Helper function to calculate distance between two coordinates
function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number | null {
  if (!lat1 || !lon1 || !lat2 || !lon2) return null;

  const R = 6371; // Radius of the Earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

type MaybeLocation = { lat?: number; lng?: number } | null;

async function fetchServices(
  api: AxiosInstance,
  userLocation: MaybeLocation,
): Promise<ApiOrg[]> {
  let allServices: ApiOrg[] = [];
  try {
    allServices = await api.get("/organisation").then((response) => {
      return response.data.map((service: ApiOrg) => {
        if (typeof service.distance === "number") {
          return service;
        }

        if (
          !userLocation?.lat ||
          !userLocation?.lng ||
          !service.lat ||
          !service.lng
        ) {
          return { ...service, distance: null };
        }

        const distance = calculateDistance(
          userLocation.lat,
          userLocation.lng,
          service.lat,
          service.lng,
        );

        return { ...service, distance };
      });
    });
  } catch (e: any) {
    throw new Error(getAxiosErrorMessage(e));
  }
  return allServices;
}

type State = {
  awaitingResponse: boolean;
  currentRange: OrgRange;
  message: Message;
  selectedServices: Set<number>;
  services: Array<ApiOrg>;
  visibleServices: Array<ApiOrg>;
};

class Services extends Component<ApiHocProps<Props>, State> {
  // TODO: These will be changed by zone, implement i18n abstraction!
  ranges = [
    { category: "near", distance: 50 },
    { category: "far", distance: 100 },
    // Not sure we need all as a category - distance doesn't matter if it's all.
    { category: "all", distance: 99999 },
  ];

  constructor(props: ApiHocProps<Props>) {
    super(props);
    this.state = {
      awaitingResponse: true,
      currentRange: this.ranges[0],
      message: { text: "" },
      // Init with an empty service - so we don't get no service message while loading.
      // It's a quickfix for until we get a spinner implemented - if it becomes a priority.
      services: [
        { distance: null, id: 0, name: "", lat: 0, lng: 0, country: "Unknown" },
      ],
      selectedServices: new Set(),
      visibleServices: [],
    };

    this.calcNewVisibleServices = this.calcNewVisibleServices.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onExpand = this.onExpand.bind(this);
  }

  getHistoryState(): { fromMyAccount?: boolean } {
    const { history = { location: null } } = this.props;

    if (history.location && history.location.state) {
      return history.location.state;
    }

    return {};
  }

  async componentDidMount() {
    // Get the user's current services
    let userServices: number[] = [];
    try {
      userServices = await this.props.Api.get("/user/services").then(
        (response) => {
          this.setState({ awaitingResponse: false });
          return response.data;
        },
      );
    } catch (e) {
      this.setState({
        awaitingResponse: false,
        message: { text: getAxiosErrorMessage(e), type: "error" },
      });
      return false;
    }

    // create a Set of the selected ones, so we can use has()
    const selectedServices = new Set([...userServices]);

    // Get all the services
    let allServices: ApiOrg[] = [];
    try {
      this.setState({ awaitingResponse: true });
      const userLocation = session.user ? session.user.location : null;
      allServices = await fetchServices(this.props.Api, userLocation);
      this.setState({ awaitingResponse: false });
    } catch (e: any) {
      this.setState({
        awaitingResponse: false,
        message: { text: e.message, type: "error" },
      });
      return false;
    }

    // re-order the list so the pre-selected are at the top.
    const preselect: ApiOrg[] = [];
    const others: ApiOrg[] = [];

    allServices.forEach(function (org) {
      if (selectedServices.has(org.id)) {
        preselect.push(org);
      } else {
        others.push(org);
      }
    });

    const services = preselect.concat(others);

    let range = this.state.currentRange;
    let visibleServices = [];

    do {
      visibleServices = this.calcNewVisibleServices(
        services,
        range,
        selectedServices,
      );
      if (visibleServices.length < 1) {
        range = this.incRange(range.category);
      }
      // While we have services but none are yet showing - this prevents infinite loop if no orgs returned.
      // Todo while this should never happen - we should handle the error.
      // For now we show the same message as if user gets to 'all' list.
    } while (visibleServices.length < 1 && services.length);

    this.setState({
      awaitingResponse: false,
      selectedServices,
      services,
      visibleServices,
      currentRange: range,
    });
  }

  onChange(orgId) {
    const { selectedServices } = this.state;
    // Since we pass the html id to the api as the org id, it should be an int.
    const id = parseInt(orgId, 10);
    if (selectedServices.has(id)) {
      selectedServices.delete(id);
    } else {
      selectedServices.add(id);
    }
    this.setState({ selectedServices });
  }

  async onSubmit(event) {
    event.preventDefault();
    if (this.state.awaitingResponse) {
      return;
    }
    this.setState({ awaitingResponse: true });
    const orgs: number[] = [];
    this.state.selectedServices.forEach((value) => orgs.push(value));
    try {
      await this.props.Api.put("/user/services", { orgs });
      this.setState({ awaitingResponse: false });
      const chosenService = parseInt(localStorage.getItem("chosen-service")!);
      if (chosenService && !orgs.includes(chosenService)) {
        localStorage.removeItem("chosen-service");
      }
    } catch (e) {
      return this.setState({
        message: {
          // @ts-expect-error no idea what this is for but trying to not make an impact
          awaitingResponse: false,
          text: getAxiosErrorMessage(e),
          type: "error",
        },
      });
    }

    const { t } = this.props;

    // Success. We could push a message here too.
    if (this.getHistoryState().fromMyAccount) {
      this.props.history.push("/account", {
        message: {
          text: t("pages:services.message_on_success"),
        },
      });
    } else {
      this.props.history.push("/thanks");
    }
  }

  calcNewVisibleServices(
    services,
    range,
    selectedServices = this.state.selectedServices,
  ) {
    const filteredServices = services.filter(function (org) {
      // Get the services the user already registered with.
      if (selectedServices.has(org.id)) {
        return true;
      }

      // If you are in NZ, you're only ever going to see NZ orgs
      if (getLocaleDefaults().tld === "nz") {
        if (
          typeof org.country !== "undefined" &&
          org.country === "New Zealand"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        // If you're not in NZ, have the lot
        if (range.category === "all") {
          return true;
        }
      }

      // Unless we are looking at all ^^ - we don't want to see any without distance values.
      // But we like 0's, they're our favourite.
      return org.distance !== null ? org.distance <= range.distance : false;
    });

    return filteredServices.sort(function (a, b) {
      // we are assuming that null distance organisations go first and set them to 0
      // we are likely to see this only on "all"
      const distance_a = a.distance !== null ? a.distance : 0;
      const distance_b = b.distance !== null ? b.distance : 0;
      return distance_a - distance_b;
    });
  }

  incRange(category) {
    let newRange;
    switch (category) {
      case "near":
        newRange = this.ranges[1];
        break;
      case "far":
      case "all":
        newRange = this.ranges[2];
        break;
      default:
        newRange = this.ranges[0];
    }
    return newRange;
  }

  onExpand(category = this.state.currentRange.category) {
    const range = this.incRange(category);
    const allServices = this.state.services;
    const newVisibleServices = this.calcNewVisibleServices(allServices, range);

    if (
      newVisibleServices.length <= this.state.visibleServices.length &&
      category !== "all"
    ) {
      this.onExpand(range.category);
    } else {
      this.setState({
        currentRange: range,
        visibleServices: newVisibleServices,
      });
    }
  }

  render() {
    const { fromMyAccount } = this.getHistoryState();
    const { t } = this.props;
    const { awaitingResponse, currentRange, services, visibleServices } =
      this.state;
    const location = session.user ? session.user.location : null;

    const submitLabel = fromMyAccount
      ? t("pages:services.button_submit_from_my_account")
      : t("pages:services.button_submit");

    return (
      <Layout>
        <LayoutHeader>
          <HeaderSection
            heading={t("pages:services.heading")}
            subheading={t("pages:services.subheading")}
            message={this.state.message}
            hideHomeButton={!fromMyAccount}
            hideLogoutButton={!fromMyAccount}
            hideAccountButton={!fromMyAccount}
          />
        </LayoutHeader>
        <LayoutContent>
          <Row justify="center">
            <Col xs={12} md={8} lg={6}>
              {location === null && (
                <LocationLink>
                  <Trans i18nKey="pages:services.link_span">
                    If you
                    {
                      <TextLink
                        to="/locator"
                        name={t("pages:services.link_locator")}
                      />
                    }
                    we can help
                  </Trans>
                </LocationLink>
              )}
              <form onSubmit={this.onSubmit}>
                <Fieldset>
                  <VisuallyHidden>
                    <legend>{t("pages:services.subheading")}</legend>
                  </VisuallyHidden>
                  <ul>
                    {visibleServices.map((org) => {
                      return (
                        <li key={org.id}>
                          <Checkbox
                            onCheckboxChange={this.onChange}
                            value={String(org.id)}
                            isSelected={this.state.selectedServices.has(org.id)}
                            label={org.name}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </Fieldset>

                {awaitingResponse && <Loading />}

                <PurpleButtonAction
                  name={t("pages:services.button_see_more")}
                  onClick={() => this.onExpand()}
                  disabled={currentRange.category === "all"}
                />

                {awaitingResponse === false &&
                currentRange.category === "all" ? (
                  <Fragment>
                    <VerticalSpacing />
                    <MessageBox
                      message={{
                        text: t("pages:services.message_use_app_anyway"),
                      }}
                    />
                  </Fragment>
                ) : null}

                {!services.length ? (
                  <MessageBox
                    // Temp until we get a spinner solution with error handling.
                    message={{
                      text: t("pages:services.message_error_no_services"),
                      type: "error",
                    }}
                  />
                ) : null}

                <SubmitButton
                  label={submitLabel}
                  name="services"
                  // No validation on form yet.
                  disabled={awaitingResponse}
                  noIcon={fromMyAccount}
                />
              </form>
            </Col>
          </Row>
        </LayoutContent>
        <LayoutFooter>
          {!fromMyAccount && <ProgressBars count={3} position={3} />}
        </LayoutFooter>
      </Layout>
    );
  }
}

const Fieldset = styled.fieldset`
  border: none;
`;

const LocationLink = styled.p`
  margin: 0 auto 2rem auto;
  text-align: center;
`;

const VerticalSpacing = styled.div`
  margin: 2rem 0;
`;

export default withTranslation()(withApi(Services));
