import axios, { AxiosInstance } from "axios";
import { useConfig } from "./components/ConfigProvider";
import AuthToken from "./auth/AuthToken";

export const useApi = () => {
  const { serviceApiUrl } = useConfig();

  const Api = axios.create({
    baseURL: serviceApiUrl + "/api",
  });

  Api.interceptors.request.use(async (config) => {
    const token = AuthToken.fetchLocal();
    config.headers["Auth-Token"] = token ? token.auth_token : null;
    const language = JSON.parse(localStorage.getItem("language")!);
    config.headers["Accept-Language"] = language ? language.code : "en";
    config.headers["Accept"] = "application/json";

    const ownerTypeCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("OwnerType="));
    if (ownerTypeCookie) {
      const ownerType = ownerTypeCookie.split("=")[1];
      config.headers["X-Owner-Type"] = ownerType;
    }

    return config;
  });

  return Api;
};

export type ApiHocProps<T> = T & {
  Api: AxiosInstance;
};

export const withApi = <T extends object>(
  Component: React.ComponentType<ApiHocProps<T>>,
) => {
  return (props: Omit<T, "Api">) => {
    const Api = useApi();
    const extendedProps = { ...props, Api } as ApiHocProps<T>;

    return <Component {...extendedProps} />;
  };
};
